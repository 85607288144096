<template>
  <div>
    <card-loading-transparent v-if="loading"></card-loading-transparent>
    <div v-if="!loading">
      <div class="text-h4" v-if="!loading && !course && $route.name != 'GhostRating'">{{form.title}}<span style="color: red; text: center"></span></div>
      <v-form ref="form" v-model="valid" :readonly="read_only">
        <personal-info v-if="form.has_personal_infos && user.role.name != 'student' || $route.name == 'GhostRating'"></personal-info>
        <form-items ref="formItems" :form_id="form.id" :ghost_rating_form_items="ghost_rating_form_items"></form-items>
        
        <div class="ml-auto mr-auto d-flex justify-end pb-5 pt-5" v-if="$route.name != 'FormShow'">
          <v-btn :disabled="!valid && !read_only" class="success px-10" @click="validateForm()">Enviar</v-btn>
        </div>
        
      </v-form>
      <div v-if="!valid && !read_only" style="position: fixed; bottom: 1vh; right: 1vw;">
        <v-tooltip left color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" large v-bind="attrs" v-on="on">
            mdi-alert
            </v-icon>
          </template>
          <span>Há campos obrigatórios vazios!</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'
import validations from '@/mixins/validations';

export default {
  name: 'FormContent',
  mixins: [
    validations
  ],
  components: {
    FormItems: () => import('./FormItems.vue'),
    PersonalInfo: () => import('../personal_info/PersonalInfo.vue'),
  },
  props: {
    course: {
      type: Object,
      default: null
    },
    ghost_rating_form: {
      type: Object,
      default: ()=> null,
    },
    ghost_rating_form_items: {
      type: Array,
      default: ()=> null,
    },
  },
  data: () => ({
    valid: false,
    loading: false,
    dialog_loading: false,
    count: 0,
    form: {},
    read_only: false,
    has_divider_items: false,
    can_emit_send_form: false
  }),
  mounted() {
    if(this.ghost_rating_form != null){
      this.setForm(this.ghost_rating_form)
    }else{
      if(this.$route.name != 'FormShow' && this.course.status == "suspended"){
        this.$router.push({name: 'CourseList'})
      }else{
        this.getForm()
      }
    }
  },
  methods: {
    getForm(){
      this.read_only = this.$route.name == 'FormShow' ? true : false
      this.loading = true
      if(this.course){
        let filter = {
          kind: (this.course.is_international_course && this.course.kind == 'in_person') ? 'abroad' : this.course.kind,
          exec_time: this.$route.name == 'CourseRating' ? 'rating' : 'enrollment'
        }
        Api.Form.index(filter).then(r => r.data).then(d => {
          this.setForm(d[0])
        }).catch(error => {
          console.log(error)
        })
      }else{
        let form_id = this.$route.params.id ? this.$route.params.id : this.$route.params.form_id
        Api.Form.show(form_id).then(r=>r.data).then(d=> {
          setTimeout(()=>{
            this.setForm(d)
          }, 1000)
        }).catch((err) => {
          this.addNotification({type: 'error', msg: `${err}`})
          if(err.response && err.response.status && err.response.status == 404){
            this.$router.push({name: 'Forms'})
          }
        });
      }
    },
    setForm(form){
      this.form = {...form}
      this.loading = false
    },
    //sendForm(){
    //  this.$emit('sendForm', this.form.id)
    //},
    async validateForm(){
      if(this.$refs.form.validate()){
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          title: `Deseja confirmar envio ?`,
          icon: 'question',
        }).then(async (r)=>{
          if (r.isConfirmed) {
            if(this.has_divider_items){
              this.$emit('sendForm', this.form.id)
            }else{
              this.has_divider_items = true
              this.$refs.formItems.getDividerItems().then(()=>{
                this.$emit('sendForm', this.form.id)
              })
            }
          };
        });
      }
    },
    ...mapActions({
      addNotification: 'Notification/add',
      clearDoneForm: 'DoneForm/clearDoneForm'
    })
  },
  computed: {
    ...mapState({
      user: state=> state.User.user,
      done_personal_info_attributes: state=> state.DoneForm.done_personal_info_attributes,
      done_items_attributes: state=> state.DoneForm.done_items_attributes,
    }),
  },
  beforeDestroy() {
    this.clearDoneForm()
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    },
    '$route.params.id'(){
      this.getForm()
    },
  },
}
</script>

<style scoped>

</style>